import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const greenAccent = {
  100: "#112b2b",
  200: "#225557",
  300: "#328082",
  400: "#43aaae",
  500: "#54d5d9",
  600: "#76dde1",
  700: "#98e6e8",
};

const getRandomColor = () => {
  const colors = Object.values(greenAccent);
  return colors[Math.floor(Math.random() * colors.length)];
};

const BubbleChart = ({ csvFile }) => {
  const svgRef = useRef();
  const [bubbleData, setBubbleData] = useState([]);

  useEffect(() => {
    // Fetch CSV file data and format it
    d3.csv(csvFile).then(data => {
      const formattedData = data.map(d => ({
        name: d.name,
        value: +d.value, // Convert value to a number
        color: getRandomColor() // Assign random color
      }));
      setBubbleData(formattedData);
    });
  }, [csvFile]);

  useEffect(() => {
    if (bubbleData.length === 0) return; // Exit if no data

    const svg = d3.select(svgRef.current)
      .attr('viewBox', '0 0 100 100')
      .attr('preserveAspectRatio', 'xMidYMid meet')
      .attr('width', '100%')
      .attr('height', '100%')
      .style('font', '6px sans-serif');

    const pack = d3.pack()
      .size([100, 100])
      .padding(0.5);

    const root = d3.hierarchy({ children: bubbleData }).sum(d => d.value);
    const nodes = pack(root).leaves();

    const tooltip = d3.select('body').append('div')
      .style('position', 'absolute')
      .style('visibility', 'hidden')
      .style('background', 'rgba(0, 0, 0, 0.7)')
      .style('color', 'white')
      .style('padding', '5px 10px')
      .style('border-radius', '5px')
      .style('font-size', '10px')
      .style('pointer-events', 'none')
      .style('transition', 'visibility 0.2s, opacity 0.2s ease');

    let timeout;

    const bubble = svg.selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      .attr('transform', d => `translate(${d.x},${d.y})`)
      .style('cursor' ,'pointer')
      .on('mouseenter', function (event, d) {
        d3.select(this).raise();

        // Enlarge the circle and fill with color
        d3.select(this).select('circle')
          .transition()
          .duration(200)
          .attr('r', d.r * 1.2)
          .attr('fill', d.data.color); // Fill with color on hover

        // Change text color to white for better contrast
        d3.select(this).selectAll('text')
          .transition()
          .duration(200)
          .style('fill', 'white');

        clearTimeout(timeout);
        tooltip.style('visibility', 'visible')
          .style('opacity', 1)
          .text(`${d.data.name}: ${d.data.value}%`)
          .style('top', `${event.pageY + 10}px`)
          .style('left', `${event.pageX + 10}px`);
      })
      .on('mousemove', function (event) {
        tooltip.style('top', `${event.pageY + 10}px`)
          .style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', function (event, d) {
        // Revert the circle size and remove fill
        d3.select(this).select('circle')
          .transition()
          .duration(200)
          .attr('r', d => d.r)
          .attr('fill', 'none'); // Remove fill on mouse leave

        // Revert text color to the original (matching stroke)
        d3.select(this).selectAll('text')
          .transition()
          .duration(200)
          .style('fill', d.data.color); // Restore original color

        timeout = setTimeout(() => {
          tooltip.style('visibility', 'hidden')
            .style('opacity', 0);
        }, 100);
      });

    // Append circles initially without fill
    bubble.append('circle')
      .attr('r', d => d.r)
      .attr('fill', 'none') // No initial fill
      .attr('stroke', d => d.data.color) // Stroke with random color
      .attr('stroke-width', 0.5);

    // Add text and make it match the color of the circle's stroke
    bubble.append('text')
      .attr('dy', '0.3em')
      .attr('text-anchor', 'middle')
      .text(d => d.data.name)
      .style('fill', d => d.data.color) // Text color matches stroke color
      .attr('font-size', d => `${Math.min(d.r / 5, 10)}px`)
      .attr('pointer-events', 'all') // Enable pointer events for text
      .on('mouseenter', function (event, d) {
        d3.select(this.parentNode).raise(); // Raise the parent group

        // Enlarge the circle and fill with color
        d3.select(this.parentNode).select('circle')
          .transition()
          .duration(200)
          .attr('r', d.r * 1.2)
          .attr('fill', d.data.color); // Fill with color on hover

        // Change text color to white for better contrast
        d3.select(this)
          .transition()
          .duration(200)
          .style('fill', 'white');

        clearTimeout(timeout);
        tooltip.style('visibility', 'visible')
          .style('opacity', 1)
          .text(`${d.data.name}: ${d.data.value}%`)
          .style('top', `${event.pageY + 10}px`)
          .style('left', `${event.pageX + 10}px`);
      })
      .on('mouseleave', function (event, d) {
        // Revert the circle size and remove fill
        d3.select(this.parentNode).select('circle')
          .transition()
          .duration(200)
          .attr('r', d => d.r)
          .attr('fill', 'none'); // Remove fill on mouse leave

        // Revert text color to the original (matching stroke)
        d3.select(this)
          .transition()
          .duration(200)
          .style('fill', d.data.color); // Restore original color

        timeout = setTimeout(() => {
          tooltip.style('visibility', 'hidden')
            .style('opacity', 0);
        }, 100);
      });

    bubble.append('text')
      .attr('dy', '1.5em')
      .attr('text-anchor', 'middle')
      .text(d => `${d.data.value}%`)
      .style('fill', d => d.data.color) // Text color matches stroke color
      .style('fill-opacity', 0.7)
      .attr('font-size', d => `${Math.min(d.r / 5, 8)}px`)
      .attr('pointer-events', 'none');

    return () => {
      tooltip.remove();
    };

  }, [bubbleData]);

  return (
    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
      <div style={{ width: '50%', height: '50%', position: 'relative', margin: '0 auto' }}>
        <svg ref={svgRef}></svg>
      </div>

      <div style={{ width: "300px" }}>
        <h3>Top 10</h3>
        <ul style={{ listStyleType: "none", padding: 0 }}>
          {bubbleData.map((d, i) => (
            <li key={i} style={{ marginBottom: "10px", border: `2px solid ${d.color}`, padding: "5px" }}>
              {d.name}: {d.value}%
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BubbleChart;
