import React, { useState } from "react";

function FeedbackForm({isCollapsed}) {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [feedback, setFeedback] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    const feedbackData = {
      name,
      email,
      feedback,
      timestamp: new Date().toLocaleString(),
    };

    const existingFeedback = JSON.parse(localStorage.getItem("userFeedback")) || [];
    existingFeedback.push(feedbackData);
    localStorage.setItem("userFeedback", JSON.stringify(existingFeedback));

    setIsSubmitted(true);
    setName("");
    setEmail("");
    setFeedback("");
  };

  const styles = {
    container: {
      marginLeft: isCollapsed ? "450px":"650px",
      maxWidth: "500px",
      margin: "2rem auto",
      padding: "2rem",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
      fontFamily: "Arial, sans-serif",
      color: "#333",
    },
    heading: {
      textAlign: "center",
      color: "#333",
      fontSize: "1.8rem",
      marginBottom: "1.5rem",
    },
    label: {
      display: "block",
      fontWeight: "bold",
      marginBottom: "0.5rem",
      color: "#666",
    },
    input: {
      width: "100%",
      padding: "0.8rem",
      marginBottom: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "1rem",
      color: "#333",
      boxSizing: "border-box",
      transition: "border-color 0.3s ease",
    },
    textarea: {
      width: "100%",
      padding: "0.8rem",
      marginBottom: "1rem",
      border: "1px solid #ddd",
      borderRadius: "4px",
      fontSize: "1rem",
      color: "#333",
      boxSizing: "border-box",
      resize: "vertical",
      height: "100px",
      transition: "border-color 0.3s ease",
    },
    button: {
      width: "100%",
      padding: "0.8rem",
      backgroundColor: "#007bff",
      border: "none",
      borderRadius: "4px",
      color: "#fff",
      fontSize: "1rem",
      fontWeight: "bold",
      cursor: "pointer",
      transition: "background-color 0.3s ease",
    },
    thankYouMessage: {
      textAlign: "center",
      padding: "1.5rem",
      color: "#4CAF50",
      fontSize: "1.2rem",
      fontWeight: "bold",
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>User Feedback Form</h2>
      {isSubmitted ? (
        <div style={styles.thankYouMessage}>
          <p>Thank you for your feedback!</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <label style={styles.label}>
            Name:
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Email:
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              style={styles.input}
            />
          </label>
          <label style={styles.label}>
            Feedback/Suggestions:
            <textarea
              value={feedback}
              onChange={(e) => setFeedback(e.target.value)}
              required
              style={styles.textarea}
            />
          </label>
          <button type="submit" style={styles.button}>
            Submit Feedback
          </button>
        </form>
      )}
    </div>
  );
}

export default FeedbackForm;
