import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import {
  Box,
  Modal,
  Button,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";

const Calendar = () => {
  const [currentEvents, setCurrentEvents] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [eventDetails, setEventDetails] = useState({
    title: "",
    start: "",
    end: "",
    repeat: "none",
    reminder: 0,
  });

  const handleDateClick = (selected) => {
    setEventDetails({
      ...eventDetails,
      start: selected.startStr,
      end: selected.endStr,
    });
    setOpenModal(true);
  };

  const handleEventClick = (selected) => {
    if (window.confirm(`Delete the event '${selected.event.title}'?`)) {
      selected.event.remove();
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleInputChange = (e) => {
    setEventDetails({ ...eventDetails, [e.target.name]: e.target.value });
  };

  const setReminder = (event) => {
    const reminderTime = event.reminder * 60000; // Convert to milliseconds
    setTimeout(() => {
      alert(`Reminder: ${event.title} is starting soon!`);
    }, reminderTime);
  };

  const handleAddEvent = () => {
    const { title, start, end, repeat } = eventDetails;
    const newEvent = {
      id: `${start}-${title}`,
      title,
      start,
      end,
      allDay: true,
    };

    // Add event
    setCurrentEvents((prevEvents) => [...prevEvents, newEvent]);

    // Handle repetition
    if (repeat !== "none") {
      let nextEventStart = new Date(start);
      let nextEventEnd = new Date(end);

      if (repeat === "daily") {
        nextEventStart.setDate(nextEventStart.getDate() + 1);
        nextEventEnd.setDate(nextEventEnd.getDate() + 1);
      } else if (repeat === "weekly") {
        nextEventStart.setDate(nextEventStart.getDate() + 7);
        nextEventEnd.setDate(nextEventEnd.getDate() + 7);
      } else if (repeat === "monthly") {
        nextEventStart.setMonth(nextEventStart.getMonth() + 1);
        nextEventEnd.setMonth(nextEventEnd.getMonth() + 1);
      }

      const repeatEvent = {
        ...newEvent,
        id: `${nextEventStart}-${title}-repeat`,
        start: nextEventStart,
        end: nextEventEnd,
      };

      setCurrentEvents((prevEvents) => [...prevEvents, repeatEvent]);
    }

    // Set reminder
    setReminder(eventDetails);
    handleModalClose();
  };

  return (
    <Box sx={{ marginLeft: "250px" }}>
      <Box m="20px">
        <h1 style={{ textAlign: "center" }}>WooBee Calendar</h1>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          initialView="dayGridMonth"
          editable={true}
          selectable={true}
          select={handleDateClick}
          eventClick={handleEventClick}
          events={currentEvents}
        />

        <Modal open={openModal} onClose={handleModalClose}>
          <Box
            sx={{
              width: 300,
              padding: 4,
              bgcolor: "white",
              margin: "auto",
              marginTop: "100px",
              borderRadius: 2,
              boxShadow: 3,
            }}
          >
            <Typography variant="h6">Add Event</Typography>
            <TextField
              label="Event Title"
              name="title"
              fullWidth
              margin="normal"
              onChange={handleInputChange}
            />
            <TextField
              label="Start Date"
              type="datetime-local"
              name="start"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
            />
            <TextField
              label="End Date"
              type="datetime-local"
              name="end"
              fullWidth
              margin="normal"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={handleInputChange}
            />
            <Select
              name="repeat"
              value={eventDetails.repeat}
              onChange={handleInputChange}
              fullWidth
              margin="normal"
            >
              <MenuItem value="none">No Repetition</MenuItem>
              <MenuItem value="daily">Daily</MenuItem>
              <MenuItem value="weekly">Weekly</MenuItem>
              <MenuItem value="monthly">Monthly</MenuItem>
            </Select>
            <TextField
              label="Reminder (minutes before)"
              type="number"
              name="reminder"
              fullWidth
              margin="normal"
              onChange={handleInputChange}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEvent}
            >
              Add Event
            </Button>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default Calendar;
