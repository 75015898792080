// src/scenes/global/Scheduler.jsx
import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Snackbar,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Fade,
  Container,
  Grid,
  Paper,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, DatePicker, TimePicker } from '@mui/x-date-pickers';

const Scheduler = ({isCollapsed}) => {
  const [eventTitle, setEventTitle] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [eventTime, setEventTime] = useState(null);
  const [scheduledEvents, setScheduledEvents] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  useEffect(() => {
    // Load events from local storage
    const events = JSON.parse(localStorage.getItem('scheduledEvents')) || [];
    setScheduledEvents(events);
  }, []);

  useEffect(() => {
    // Save events to local storage whenever scheduledEvents changes
    localStorage.setItem('scheduledEvents', JSON.stringify(scheduledEvents));
  }, [scheduledEvents]);

  const handleSchedule = (e) => {
    e.preventDefault();
    if (!eventTitle || !eventDate || !eventTime) return;

    const newEvent = { title: eventTitle, date: eventDate, time: eventTime };
    setScheduledEvents([...scheduledEvents, newEvent]);

    setEventTitle('');
    setEventDate(null);
    setEventTime(null);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleDeleteEvent = (index) => {
    const updatedEvents = scheduledEvents.filter((_, i) => i !== index);
    setScheduledEvents(updatedEvents);
  };

  return (
    <Container sx={{ mt: 4, mb: 4, marginLeft: isCollapsed? "80px" : "250px" }}>
      <Paper elevation={3} sx={{ padding: 4, borderRadius: 2 }}>
        <Typography variant="h4" align="center" gutterBottom>
          Schedule a Meeting
        </Typography>
        <form onSubmit={handleSchedule}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Event Title"
                variant="outlined"
                fullWidth
                value={eventTitle}
                onChange={(e) => setEventTitle(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Event Date"
                  value={eventDate}
                  onChange={(newValue) => setEventDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                  label="Event Time"
                  value={eventTime}
                  onChange={(newValue) => setEventTime(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button
                variant="contained"
                color="primary"
                type="submit"
                fullWidth
                sx={{
                  fontWeight: 'bold',
                  '@media (max-width: 600px)': { fontSize: '0.8rem' },
                }}
              >
                Schedule Event
              </Button>
            </Grid>
          </Grid>
        </form>

        <Typography variant="h5" mt={4} gutterBottom>
          Scheduled Events
        </Typography>
        <List>
          {scheduledEvents.map((event, index) => (
            <Fade in key={index}>
              <ListItem
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  backgroundColor: '#f0f',
                  borderRadius: 1,
                  mb: 1,
                }}
              >
                <ListItemText
                  primary={`${event.title} - ${event.date.toLocaleDateString()} at ${event.time?.toLocaleTimeString()}`}
                  sx={{
                    fontWeight: 'medium',
                    '@media (max-width: 600px)': { fontSize: '0.9rem' },
                  }}
                />
                <IconButton
                  edge="end"
                  aria-label="delete"
                  onClick={() => handleDeleteEvent(index)}
                >
                  <CloseIcon />
                </IconButton>
              </ListItem>
            </Fade>
          ))}
        </List>
      </Paper>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="Event scheduled successfully!"
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleSnackbarClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Container>
  );
};

export default Scheduler;
